import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import { Button, Col, Row } from "antd"
import cx from "classnames"
import { Link } from "gatsby"
import { navigate } from "gatsby-link"
import React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useIntl } from "react-intl"

import styles from "./style.module.less"

const WarningBill = ({ data }) => {
  const { plan, billDate, planDate, value, billNumber } = data
  const intl = useIntl()

  const clipBoardText = intl.formatMessage({ id: "copyCode" })

  return (
    <Row
      justify="center"
      className={cx("pt48 warningBillContext", styles.warningBillContext)}
    >
      <Col span={20} lg={18} xl={16} offset={0} className="flex-column tc">
        <span className="f20 fw5 mine-shaft mb24">
          {translate("loseAnonimattaAccess")}
        </span>
        <span className="f14 normal scorpion-main">
          {translate("warningBill.planDescription", {
            plan: <span className="b">{plan}</span>,
            value: value,
          })}
        </span>
        <span className="b f14 scorpion-main mb24">{planDate}</span>
        <span className="f14 scorpion-main">
          {translate("warningBill.paymentDate")}
        </span>
        <span className="b f14 scorpion-main mb32">{billDate}</span>
        <span className="f14 fw5 scorpion-main mb12">
          {translate("billNumber")}
        </span>
        <span className="f16 fw5 mine-shaft mb12">{billNumber}</span>
        <CopyToClipboard text={billNumber}>
          <Button type="text">
            <div className="flex items-center justify-center">
              <Icon name="Clipboard" />
              <span className="fw5 f12 scorpion-main ml8">{` ${clipBoardText}`}</span>
            </div>
          </Button>
        </CopyToClipboard>
        <Link className="mv24" to="/boleto/boleto-expirado">
          <span className="kimberly underline fw6 f14 mv24">
            {translate("editPayment")}
          </span>
        </Link>
        <Button
          type="primary"
          block
          className={cx("self-center", styles.button)}
          onClick={() => navigate("/boleto/boleto-expirado")}
        >
          {translate("downloadBill")}
        </Button>
        <span
          className={cx(
            "f10 scorpion-main mt12 mb12 mb24-ns tc",
            styles.whiteSpace
          )}
        >
          {translate("billPayed")}
        </span>
      </Col>
    </Row>
  )
}

export default WarningBill
