import "./style.module.less"

import MyAccountModal from "@components/myAccountModal"
import TranslateWrapper from "@components/TranslateWrapper"
import WarningBill from "@modules/Payment/WarningBill"
import React, { useEffect, useState } from "react"

const BillPage = () => {
  const [visible, setVisible] = useState(true)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleClose = () => {
    setVisible(false)
  }

  const data = {
    plan: "Trimestral",
    value: 107.76,
    planDate: "28/10/2020",
    billDate: "30/10/2020",
    billNumber:
      "12345123124124123124123124123167890987654321234567890987654321",
  }

  if (!isClient) return null

  return (
    <MyAccountModal
      visible={visible}
      handleClose={handleClose}
      className="mc billsContext"
    >
      <TranslateWrapper>
        <WarningBill data={data} />
      </TranslateWrapper>
    </MyAccountModal>
  )
}

export default BillPage
